<template>
  <nav
    class="fixed w-full top-0 left-0 right-0 z-50 bg-gradient-to-b from-orange-400 from-0% via-orange-300 via-100% to-orange-700 to-100% text-white py-1 shadow-md"
  >
    <div class="flex px-2 basis-full" style="">
      <div
        class="flex grow flex-wrap basis-full sm:justify-center md:justify-start lg:justify-start"
      >
        <div
          class="flex grow sm:w-full md:w-1/4 sm:justify-center md:justify-start lg:justify-start"
        >
          <h1 class="text-2xl font-sans lg:mt-0 place-content-center">
            FlappyETH
          </h1>
        </div>
        <BalanceDisplay></BalanceDisplay>
        <WalletConnectButton></WalletConnectButton>
      </div>
    </div>
  </nav>
</template>

<script>
import WalletConnectButton from "@/components/WalletConnectButton.vue";
import BalanceDisplay from "@/components/BalanceDisplay.vue";

export default {
  name: "AppNavigation",
  components: {
    WalletConnectButton,
    BalanceDisplay,
  },
};
</script>

<style>
/* Header-specific styles can go here, if needed */
</style>
