<!-- LeaderBoard.vue -->
<template>
  <div v-if="isInitialized">
    <div>
      <h1 class="text-2xl font-sans text-white p-5 text-center">
        All Time Top 10
      </h1>
    </div>
    <div
      v-for="(entry, index) in leaderboard"
      :key="index"
      class="border-2 mb-5 text-center"
    >
      {{ formatAddress(entry.web3Address) }} : {{ entry.bestScoreEver }} FUDs
      dodged
    </div>
  </div>
  <div v-else class="text-center">
    <span class="text-white">Loading...</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LeaderBoard",
  computed: {
    ...mapState({
      leaderboard: (state) => state.leaderboard,
      web3: (state) => state.web3,
      web3Address: (state) => state.web3Address,
      GameContract: (state) => state.GameContract,
    }),
    isInitialized() {
      return this.web3 && this.web3Address && this.GameContract;
    },
  },
  methods: {
    formatAddress(address) {
      return `${address.substring(0, 4)}...${address.substring(
        address.length - 4
      )}`;
    },
  },
  // Any header-specific logic or data properties can go here
};
</script>

<style>
/* Header-specific styles can go here, if needed */
</style>
