// userSessionManager.js
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex"; // Assuming you're using Vuex
import { useToast } from "vue-toastification";

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const TOKEN_CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

export function useUserSessionManager() {
  const store = useStore();
  const toast = useToast();
  const lastActivity = ref(Date.now());
  let inactivityTimer;
  let tokenCheckInterval;

  const resetActivityTimer = () => {
    lastActivity.value = Date.now();
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(handleInactivity, INACTIVITY_TIMEOUT);
  };

  const handleInactivity = async () => {
    console.log("User has been inactive");
    await checkTokenValidity();
  };

  const checkTokenValidity = async () => {
    try {
      // Assuming you have an action in your store to check token validity
      const isValid = await store.dispatch("checkTokenValidity");
      if (!isValid) {
        toast.info(
          "Your session has expired. Attempting to re-authenticate..."
        );
        await store.dispatch("reauthenticate");
      }
    } catch (error) {
      console.error("Error checking token validity:", error);
      toast.error("An error occurred. Please refresh the page and try again.");
    }
  };

  onMounted(() => {
    // Set up event listeners for user activity
    ["mousedown", "keydown", "touchstart", "scroll"].forEach((event) => {
      window.addEventListener(event, resetActivityTimer);
    });

    // Initial setup
    resetActivityTimer();
    tokenCheckInterval = setInterval(checkTokenValidity, TOKEN_CHECK_INTERVAL);
  });

  onUnmounted(() => {
    // Clean up
    ["mousedown", "keydown", "touchstart", "scroll"].forEach((event) => {
      window.removeEventListener(event, resetActivityTimer);
    });
    clearTimeout(inactivityTimer);
    clearInterval(tokenCheckInterval);
  });

  return {
    lastActivity,
    resetActivityTimer,
    checkTokenValidity,
  };
}
