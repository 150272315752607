<!-- Header.vue -->
<template>
  <div class="">
    <div>
      <h1 class="text-2xl font-sans text-white p-5 text-center">Stats</h1>
    </div>
    <div class="border-2 mb-5 text-center">158 airdrop participants</div>
    <div class="border-2 text-center">200m flappy earned</div>
  </div>
</template>

<script></script>

<style>
/* Header-specific styles can go here, if needed */
</style>
