<template>
  <div v-if="!web3Address" class="container mx-auto p-4">
    <div class="text-center w-3/4 mx-auto">
      <p
        class="sm:text-1xl md:text-2xl lg:text-3xl font-semibold text-red-500 mb-4"
      >
        Wallet Not Connected
      </p>
      <p class="sm:text-sm md:text-md lg:text-lg text-gray-300 mb-4">
        Please connect your wallet to continue.
      </p>
    </div>
  </div>
  <div v-else>
    <div
      v-if="gameState === 0 || gameState === 2 || gamesLeft === 0"
      class="container mx-auto p-4"
    >
      <div v-if="gameState === 0" class="text-center w-3/4 mx-auto">
        <h2 class="text-3xl font-semibold text-yellow-500 mb-4">
          Funding Required
        </h2>
        <p class="text-lg text-gray-300 mb-4">
          Current Prize Pool: {{ prizePool }} / 10 W3M
        </p>

        <DepositComponent />
      </div>

      <div
        v-if="gameState === 2 && allocation > 0"
        class="text-center w-3/4 mx-auto"
      >
        <h2 class="text-3xl font-semibold text-purple-500 mb-4">
          Claim Your Prize
        </h2>
        <p class="text-lg text-gray-300 mb-4">
          Your prize pool share: {{ allocation }} Flappy
        </p>
        <p class="text-lg text-gray-300 mb-4">
          Time left to claim: {{ formattedCountdown }}
        </p>
        <p>
          <button
            class="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            @click="claimAllocation"
          >
            Claim Allocation
          </button>
        </p>
      </div>

      <div
        v-if="gameState === 2 && allocation == 0"
        class="text-center w-3/4 mx-auto"
      >
        <h2 class="text-3xl font-semibold text-purple-500 mb-4">
          Round is over
        </h2>
        <p class="text-lg text-gray-300 mb-4">
          Claim Period Ends in: {{ formattedCountdown }}
        </p>
      </div>

      <div
        v-if="gameState === 2 && allocation === 0"
        class="text-center w-3/4 mx-auto"
      >
        <h2 class="text-3xl font-semibold text-purple-500 mb-4">
          Waiting for next round....
        </h2>
      </div>

      <div
        v-if="gameState == 1 && gamesLeft === 0"
        class="text-center w-4/4 mx-auto"
      >
        <h2
          class="sm:text-2xl md:text-3xl lg:text-3xl font-semibold text-red-500 mb-4"
        >
          No Games Left
        </h2>
        <p class="text-lg text-gray-300 mb-4">
          Your best score is {{ highScore }}!
        </p>
        <SignMessageButton />
      </div>
    </div>
    <div
      v-if="
        isGameVisible && gamesLeft != 0 && gamesLeft != null && gameState === 1
      "
      id="gamecontainer"
      class="game-container lg:block"
      tabindex="0"
    >
      <div id="gamescreen">
        <div id="sky" class="animated">
          <div id="flyarea">
            <div id="ceiling" class="animated"></div>
            <div id="player" class="bird animated"></div>
            <div id="bigscore"></div>
            <div id="splash">
              <div class="flex flex-col">
                <h2 class="text-2xl text-white">Get Ready</h2>
                <h3 class="text-base mt-8 text-gray-300">
                  Try to pass as many obstacles as possible to reach the Top 5
                </h3>
                <div class="flex-auto m-auto w-3/4 align-top">
                  <ul class="list-decimal list-inside mt-12 text-gray-300">
                    <li class="text-left">
                      You have 10 Games in total ({{ gamesLeft }} left)
                    </li>
                    <li class="text-left">
                      Highest Score can be submitted after game round is over
                      (currently {{ highScore }})
                    </li>
                    <li class="text-left">
                      Top 5 players share the prize pool
                    </li>
                  </ul>
                </div>
                <p
                  class="animate-pulse text-custom-orange font-bold py-2 px-4 rounded-full mt-12 cursor-pointer"
                  @click="startGame"
                >
                  Click here to start! LFG!
                </p>
              </div>
            </div>
            <div id="scoreboard">
              <div id="currentscore"></div>
              <div id="highscore"></div>
              <div id="replay"></div>
            </div>
          </div>
        </div>
        <div id="land" class="animated">
          <div id="debug"></div>
        </div>
        <div class="boundingbox" id="playerbox"></div>
        <div class="boundingbox" id="pipebox"></div>
      </div>
    </div>
    <div v-else-if="gamesLeft == null" class="text-center w-4/4 mx-auto">
      <h2
        class="sm:text-2xl md:text-3xl lg:text-3xl font-semibold text-red-500 mb-4"
      >
        Something is wrong here, mate!
      </h2>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import apiService from "../assets/js/apiService.js";
import SignMessageButton from "./SignMessageButton.vue";
import { connect } from "@kolirt/vue-web3-auth";
import DepositComponent from "./DepositComponent.vue";

export default {
  name: "GameComponent",
  components: {
    SignMessageButton,
    DepositComponent,
  },
  data() {
    return {
      gameStartedHandler: null,
    };
  },
  setup() {
    const store = useStore();
    const isGameVisible = computed(() => store.state.isGameVisible);
    const gamesLeft = computed(() => store.state.gamesLeft);
    const highScore = computed(() => store.state.highestScore);
    const contract = computed(() => store.state.GameContract);
    const gameState = computed(() => store.state.gameState);
    const prizePool = computed(() => store.state.prizePool);
    const web3Address = computed(() => store.state.web3Address);
    const allocation = computed(() => store.state.allocation);
    const claimEndTime = computed(() => store.state.claimEndTime);
    const isInitialized = computed(() => store.state.isInitialized);

    const countdown = ref(0);
    const isScriptLoaded = computed(() => store.state.gameScriptsLoaded);

    const updateCountdown = () => {
      if (countdown.value > 0) {
        countdown.value -= 1;
      } else {
        clearInterval(timer);
      }
    };

    let timer = null;

    watch(claimEndTime, (newVal) => {
      if (newVal > 0) {
        countdown.value = newVal;
        if (timer) {
          clearInterval(timer);
        }
        timer = setInterval(updateCountdown, 1000);
      }
    });

    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    const formattedCountdown = computed(() => {
      const days = Math.floor(countdown.value / (60 * 60 * 24));
      const hours = Math.floor((countdown.value % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((countdown.value % (60 * 60)) / 60);
      const seconds = countdown.value % 60;

      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    });
    const claimAllocation = async () => {
      try {
        if (contract.value.methods.claimAllocation) {
          await contract.value.methods
            .claimAllocation()
            .send({ from: web3Address.value });
          console.log("Allocation claimed");
        } else {
          console.error("Contract method 'claimAllocation' not found.");
        }
      } catch (error) {
        console.error("Failed to claim allocation:", error);
      }
    };

    return {
      isGameVisible,
      gamesLeft,
      highScore,
      gameState,
      web3Address,
      prizePool,
      allocation,
      countdown,
      isInitialized,
      formattedCountdown,
      claimAllocation,
      isScriptLoaded,
    };
  },
  watch: {
    isInitialized() {
      this.checkGameInitialization();
    },
  },
  async mounted() {
    try {
      // Check if we need to initialize
      this.checkGameInitialization();
    } catch (error) {
      console.error("Script loading error:", error);
    }
    this.gameStartedHandler = (event) => {
      event.stopPropagation();
      this.handleGameStarted(event);
    };
    window.addEventListener("gameStarted", this.gameStartedHandler);
    window.addEventListener("increment", this.handleIncrement);
    window.addEventListener("gameEnded", this.handleGameEnded);

    if (this.isInitialized) {
      await this.fetchAllocation();
    }
  },
  beforeUnmount() {
    window.removeEventListener("gameStarted", this.gameStartedHandler);
    window.removeEventListener("increment", this.handleIncrement);
    window.removeEventListener("gameEnded", this.handleGameEnded);
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        script.onload = resolve;
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.head.appendChild(script);
      });
    },
    checkGameInitialization() {
      if (
        this.isScriptLoaded &&
        this.isInitialized &&
        this.isGameVisible &&
        this.gamesLeft !== 0 &&
        this.gameState === 1
      ) {
        console.log("All conditions met, attempting to initialize game");
        this.$nextTick(() => {
          if (typeof window.initGame === "function") {
            window.initGame();
          } else {
            console.error("window.initGame is not a function");
          }
        });
      } else {
        console.log("Not all conditions met for game initialization:");
        console.log("Script loaded:", this.isScriptLoaded);
        console.log("Initialized:", this.isInitialized);
        console.log("Game visible:", this.isGameVisible);
        console.log("Games left:", this.gamesLeft);
        console.log("Game state:", this.gameState);
      }
    },
    toggleGameVisibility() {
      this.$store.commit("SET_GAME_VISIBILITY", !this.isGameVisible);
      console.log("Toggled isGameVisible to:", this.$store.state.isGameVisible);
    },
    handleSignature(signature) {
      console.log("Received signature:", signature);
    },
    initGameManually() {
      if (window.initGame) {
        window.initGame();
        console.log("game initialized");
      }
    },
    async handleGameStarted() {
      console.log("handleGameStarted called", new Date().getTime());
      let token = localStorage.getItem("userToken");
      try {
        const response = await apiService.logGameStart({ token });
        this.$store.commit("SET_GAME_TOKEN", response.data.gameToken);
      } catch (error) {
        console.error("Error during game start:", error);
      }
    },
    async handleIncrement() {
      let token = localStorage.getItem("gameToken");
      try {
        const response = await apiService.logIncrement({ token });
        this.$store.commit("SET_GAME_TOKEN", response.data.gameToken);
      } catch (error) {
        console.error("Error during game score incrementation:", error);
      }
    },
    async handleGameEnded() {
      let token = localStorage.getItem("userToken");
      await apiService.logGameEnd({ token });
    },
    sendDataToGame(data) {
      const event = new CustomEvent("dataForGame", { detail: data });
      window.dispatchEvent(event);
    },
    async handleConnect() {
      try {
        await connect(); // Wait for connect() to complete
      } catch (error) {
        console.error("Error connecting:", error);
      }
    },
    async fetchAllocation() {
      try {
        await this.$store.dispatch("fetchAllocation");
      } catch (error) {
        console.error("Error fetching allocation:", error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #1a202c;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 75%; /* This sets the width to 3/4 */
  margin-left: auto;
  margin-right: auto;
}

.btn-primary {
  background-color: #3b82f6;
  &:hover {
    background-color: #2563eb;
  }
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
}
</style>
