<template>
  <div class="flex basis-2/4 justify-center justify-items-center">
    <button
      v-if="!scoreSubmitted"
      class="bg-green-500 border-2 border-black text-black text-center text-2xl px-4 py-2 rounded-md sm:flex md:flex lg:flex hover:bg-green-700 hover:border-white hover:text-white transition-colors duration-300"
      @click="signScore"
    >
      Submit to highscore
    </button>
    <div
      v-else
      class="bg-green-500 border-2 border-black text-black text-center text-xl px-4 py-2 rounded-md sm:flex md:flex lg:flex"
    >
      Score has been submitted. Plz wait until round is over.
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { signMessage } from "@kolirt/vue-web3-auth";
import apiService from "../assets/js/apiService.js";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const store = useStore();
    const toast = useToast();

    const score = computed(() => store.state.highestScore);
    const web3Address = computed(() => store.state.web3Address);
    const scoreSubmitted = computed(() => store.state.scoreSubmitted);

    const signScore = async () => {
      try {
        const signature = await signMessage(score.value.toString());
        const result = await apiService.verifySignature(
          signature,
          score.value,
          web3Address.value
        );
        if (result.success) {
          console.log("Signature verified successfully");
          toast.success("Score submitted!");
          await store.dispatch("setScoreSubmitted", true);
        } else {
          console.error("Failed to verify signature");
          toast.error("Something went wrong.");
        }
      } catch (error) {
        console.error("Error signing message:", error);
        toast.error("Error signing message.");
      }
    };

    return {
      score,
      web3Address,
      scoreSubmitted,
      signScore,
    };
  },
  data() {
    return {
      message: "Hello, please sign this message to prove you own the wallet.",
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
