<template>
  <div class="deposit-container">
    <h3 class="text-xl font-semibold text-yellow-500 mb-2">Deposit Funds</h3>
    <div class="flex items-center justify-center space-x-2">
      <input
        v-model="depositAmount"
        type="number"
        placeholder="Amount to deposit"
        class="bg-gray-700 text-white px-3 py-2 rounded"
        :disabled="isDepositing"
      />
      <button
        @click="handleDeposit"
        class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        :disabled="isDepositing || !web3Address"
      >
        {{ isDepositing ? "Depositing..." : "Deposit" }}
      </button>
    </div>
    <p v-if="depositError" class="text-red-500 mt-2">{{ depositError }}</p>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "DepositComponent",
  setup() {
    const store = useStore();
    const depositAmount = ref("");
    const isDepositing = ref(false);
    const depositError = ref("");
    const web3 = store.state.web3;
    const web3Address = computed(() => store.state.web3Address);
    const contract = computed(() => store.state.GameContract);
    const tokenContract = computed(() => store.state.W3MContract);

    const handleDeposit = async () => {
      if (!depositAmount.value || isNaN(depositAmount.value)) {
        depositError.value = "Please enter a valid amount";
        return;
      }

      isDepositing.value = true;
      depositError.value = "";

      try {
        const amount = web3.utils.toWei(depositAmount.value, "ether");
        const spenderAddress = contract.value._address;
        console.log("Approving tokens...");

        const approvalReceipt = await tokenContract.value.methods
          .approve(spenderAddress, amount)
          .send({ from: web3Address.value, gas: "600000" });

        if (approvalReceipt.status) {
          console.log(amount + " Tokens approved for spending by contract.");
          toast.success(depositAmount.value + " Token approved to spend");
          console.log("Waiting for approval transaction to be mined...");

          let txReceipt = await web3.eth.getTransactionReceipt(
            approvalReceipt.transactionHash
          );
          console.log("Receipt received: " + txReceipt);
          console.log("Approval transaction mined. Proceeding with deposit...");
          // Add a delay of 5 seconds
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const depositReceipt = await contract.value.methods
            .depositFunds(amount)
            .send({ from: web3Address.value });

          if (depositReceipt.status) {
            console.log("Funds deposited:", amount);
            toast.success("Funds deposited");
            await store.dispatch("fetchPrizePool");
            window.location.reload();
            depositAmount.value = "";
          } else {
            throw new Error("Deposit transaction failed");
          }
        } else {
          throw new Error("Approval transaction failed");
        }
      } catch (error) {
        console.error("Deposit error:", error);
        if (error.message.includes("User rejected the request")) {
          depositError.value =
            "Transaction was cancelled. Please try again if you want to proceed with the deposit.";
        } else if (error.message.includes("Approval transaction failed")) {
          depositError.value = "Token approval failed. Please try again.";
        } else if (error.message.includes("Deposit transaction failed")) {
          depositError.value =
            "Deposit failed, but tokens were approved. You may need to reset your allowance.";
        } else {
          depositError.value =
            "An error occurred during the deposit process. Please try again.";
        }
      } finally {
        isDepositing.value = false;
      }
    };

    return {
      depositAmount,
      isDepositing,
      depositError,
      handleDeposit,
      web3Address,
    };
  },
};
</script>

<style scoped>
.deposit-container {
  /* Add any specific styles for the deposit container */
}
</style>
