<template>
  <div>
    <div class="relative flex items-center justify-center">
      <h1 class="text-2xl font-sans text-white p-5 text-center">
        Your Prize Share
        <i
          class="material-icons text-blue-500 cursor-pointer"
          v-tippy="{
            content:
              'Your allocation in the prize pool based on your score. Claimable after the current round ends.',
          }"
          >info</i
        >
      </h1>
    </div>
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else>
      <div v-if="allocation > 0">
        <div
          class="border-2 border-yellow-600 bg-yellow-100 mb-5 text-center text-black"
        >
          <p>{{ allocation }} W3M</p>
        </div>
        <div
          v-if="allocation > 0 && gameState == 2"
          class="animate-pulse border-2 border-green-600 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% mb-5 text-center"
        >
          <button @click="claimAllocation">Claim</button>
        </div>
      </div>
      <div
        v-else
        class="border-2 border-red-600 bg-red-100 mb-5 text-center text-black"
      >
        <p>No allocation</p>
      </div>
    </div>
    <div class="mb-2 text-center text-xs">
      Based on your score of {{ score }}.
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "AirdropAllocation",
  setup() {
    const store = useStore();
    const isGameVisible = computed(() => store.state.isGameVisible);
    const gamesLeft = computed(() => store.state.gamesLeft);
    const score = computed(() => store.state.highestScore);
    const gameState = computed(() => store.state.gameState);
    const contract = computed(() => store.state.GameContract);
    const web3Address = computed(() => store.state.web3Address); // Add web3Address from the store
    const allocation = computed(() => store.state.allocation); // Add allocation from the store
    const remainingTime = computed(() => store.state.remainingTime); // Add remainingTime from the store
    const isInitialized = computed(() => store.state.isInitialized); // Add isInitialized from the store

    async function claimAllocation() {
      try {
        if (contract.value.methods.claimAllocation) {
          await contract.value.methods
            .claimAllocation()
            .send({ from: web3Address.value });
          console.log("Allocation claimed");
          window.location.reload();
        } else {
          console.error("Contract method 'claimAllocation' not found.");
        }
      } catch (error) {
        console.error("Failed to claim allocation:", error);
      }
    }

    return {
      isGameVisible,
      gamesLeft,
      score,
      gameState,
      web3Address,
      allocation,
      remainingTime,
      isInitialized,
      claimAllocation, // Adding the claimAllocation function to the returned object
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
}

.loading-text {
  color: white;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, red, transparent);
  animation: knightRiderAnimation 2s infinite linear;
}

@keyframes knightRiderAnimation {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

.material-icons {
  vertical-align: baseline;
}
</style>
