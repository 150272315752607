// main.js

import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import { createWeb3Auth } from "@kolirt/vue-web3-auth";
import store from "./store";
import "tippy.js/dist/tippy.css";
import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import chainConfig from "./chainConfig.js";

const currentEnv = process.env.VUE_APP_ENV || "local"; // Default to 'local' if not set
const currentChain = chainConfig[currentEnv];
console.log(currentChain);
const options = {
  // You can set your default options here
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

const eth_app = createApp(App);
eth_app.use(
  createWeb3Auth({
    projectId: "451578462318628bcba5622853f8c909", // generate here https://cloud.walletconnect.com/ and turn on 'Supports Sign v2'
    chains: [currentChain],
    enableCustomProvider: true,
  })
);
eth_app.provide("chainConfig", chainConfig);
eth_app.use(store);
eth_app.use(Toast, options);
eth_app.use(VueTippy, {
  directive: "tippy", // => v-tippy
  component: "tippy", // => <tippy/>
  componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
  defaultProps: {
    placement: "top",
    allowHTML: true,
  },
});

//let isConnected = account.connected;
// Check for persisted wallet connection
//const walletConnected = localStorage.getItem("walletConnected");
//console.log("MAIN.JS says: Account is connected:" + isConnected);
eth_app.mount("#eth_app");
