<!-- Header.vue -->
<template>
  <div class="text-center">
    <div>
      <h1 class="text-2xl font-sans text-white p-5">CURRENT PRIZE POOL</h1>
    </div>
    <div
      class="animate-pulse border-2 border-green-600 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% mb-5 text-3xl"
    >
      {{ prizePool }} FLAPPY
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RightButtonArea",
  computed: {
    ...mapState({
      prizePool: (state) => state.prizePool,
    }),
  },
};
</script>

<style>
/* Header-specific styles can go here, if needed */
</style>
