<template>
  <div
    class="game-requirements flex sm:basis-4/5 md:basis-3/5 lg:basis-3/5 flex-wrap justify-center items-center place-content-center"
  >
    <div
      class="requirement basis-full justify-evenly items-center place-content-center sm:flex-col sm:gap-y-4 md:flex-row md:grid md:grid-cols-4 lg:flex-row"
      :class="{ met: hasEnoughTokens }"
    >
      <span class="icon">{{ hasEnoughTokens ? "✅" : "❌" }}</span>
      <div class="flex place-content-center justify-evenly items-center">
        Hold at least 200 $Flappy
      </div>
      <a v-if="!hasEnoughTokens" :href="buyTokenUrl" target="_blank">
        <button @click="buyToken">Buy 200 $Flappy</button>
      </a>
    </div>
    <div
      class="requirement basis-full justify-center items-center place-content-center sm:flex-col sm:gap-y-4 md:flex-row lg:flex-row"
      :class="{ met: hasEntryFee }"
    >
      <span class="icon">{{ hasEntryFee ? "✅" : "❌" }}</span>
      <span>unlock round for 6 $ Flappy </span>
      <button
        v-if="!hasEntryFee"
        @click="payEntryFee"
        :disabled="!canPayEntryFee"
      >
        Unlock now
      </button>
    </div>
    <div
      v-if="!canPlayGame"
      class="warning flex basis-full justify-center items-center place-content-center"
    >
      You need to meet both requirements to play the game.
    </div>
    <div v-else>
      <button @click="refreshPage">letsGo</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "GameRequirements",
  data() {
    return {
      buyTokenUrl: "https://app.uniswap.org/swap?chain=arbitrum",
    };
  },
  computed: {
    ...mapState(["userBalance", "hasEntryFee"]),
    ...mapGetters(["canPlayGame"]),
    hasEnoughTokens() {
      return parseFloat(this.userBalance) >= 200;
    },
    canPayEntryFee() {
      return parseFloat(this.userBalance) >= 6;
    },
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    async payEntryFee() {
      try {
        await this.$store.dispatch("payEntryFee");
      } catch (error) {
        console.error("Failed to pay entry fee:", error);
      }
    },
  },
};
</script>

<style scoped>
.game-requirements {
}

h2 {
  color: #333;
  margin-bottom: 15px;
}

.requirement {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: black;
  border-radius: 5px;
}

.requirement.met {
  background-color: #4caf50;
}

.icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.balance {
  margin-left: auto;
  color: #666;
}

button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.warning {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-top: 15px;
  border-radius: 5px;
}
</style>
