<template>
  <div>
    <button v-if="isWalletConnected" class="btn btn-blue">
      {{ formattedBalance }} FLAPPY
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "BalanceDisplay",

  computed: {
    ...mapState(["userBalance"]),
    ...mapGetters(["isWalletConnected"]),
    formattedBalance() {
      return parseFloat(this.userBalance).toFixed(2);
    },
  },

  methods: {
    ...mapActions(["fetchUserBalance"]),
  },

  mounted() {
    if (this.isWalletConnected) {
      this.fetchUserBalance();
    }
  },

  watch: {
    isWalletConnected(newValue) {
      if (newValue) {
        this.fetchUserBalance();
      }
    },
  },
};
</script>

<style scoped>
.btn {
  @apply font-bold py-2 px-4 mx-1 my-1 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}
</style>
