<template>
  <div
    class="flex sm:place-content-center md:place-content-end lg:place-content-end"
  >
    <div v-if="account.connected" class="flex md:justify-end lg:justify-end">
      <button class="btn btn-blue" @click="accountDetails()">
        {{ formatAddress(account.address) }}
      </button>
      <button class="btn btn-red" @click="handleDisconnect()">
        Disconnect
      </button>
    </div>
    <div v-else class="flex grow sm:justify-end md:justify-end lg:justify-end">
      <button class="btn btn-green" @click="handleConnect()">
        Connect wallet
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const previousAddress = ref(null);
import { watch, computed, ref } from "vue";
import {
  account,
  accountDetails,
  connect,
  disconnect,
} from "@kolirt/vue-web3-auth";

const store = useStore();
// Function to handle initialization when the wallet is connected

const isConnected = computed(() => account.connected);

async function initializeWallet() {
  try {
    await store.dispatch("initializeContracts");
    await store.dispatch("fetchInitialData");
    console.log("Initialization and data fetching completed successfully");
  } catch (error) {
    console.error("Failed to initialize or fetch data:", error);
    handleDisconnect();
  }
}

watch(isConnected, async (newValue) => {
  console.log("WATCHER TRIGGERED");
  if (newValue) {
    await initializeWallet();
  } else {
    handleDisconnect();
  }
});

// Watch for account address changes
watch(
  () => account.address,
  async (newAddress, oldAddress) => {
    if (oldAddress && newAddress !== oldAddress) {
      console.log("Wallet switched, reinitializing...");
      await store.dispatch("clearUserData");
      await initializeWallet();
    }
    previousAddress.value = newAddress;
  }
);

function formatAddress(address) {
  return `${address.substring(0, 4)}...${address.substring(
    address.length - 4
  )}`;
}

async function handleDisconnect() {
  disconnect(); // Disconnect the account
  localStorage.removeItem("walletConnected"); // Remove the persisted connection state
  localStorage.removeItem("userToken"); // Also remove the user token
  await store.dispatch("clearUserData");
}

async function handleConnect() {
  try {
    await connect(); // Wait for connect() to complete
    // Wait for the connection to be established
    await new Promise((resolve, reject) => {
      const timeout = setTimeout(
        () => reject(new Error("Connection timeout")),
        60000
      ); // 1 minute timeout
      const checkConnection = setInterval(() => {
        if (account.connected) {
          clearInterval(checkConnection);
          clearTimeout(timeout);
          resolve();
        }
      }, 1000); // Check every second
    });
    console.log("Account connected:" + account.connected);
    if (account.connected) {
      localStorage.setItem("walletConnected", "true");
      //await initializeWallet();
      // The watch function will handle the rest of the initialization
    }
  } catch (error) {
    console.error("Error connecting:", error);
  }
}
</script>

<style scoped>
.btn {
  @apply font-bold py-2 px-4 mx-1 my-1 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}

.btn-blue {
  @apply bg-blue-500 text-white;
}

.btn-green {
  @apply bg-green-500 text-white;
}

.btn-red {
  @apply bg-red-500 text-white;
}

.btn-blue:hover {
  @apply bg-blue-700;
}
</style>
