import { createStore } from "vuex";
import Web3 from "web3";
import apiService from "../src/assets/js/apiService";
import { account } from "@kolirt/vue-web3-auth";
import axios from "axios";
import { useToast } from "vue-toastification";
import { isWithinPastHours } from "./assets/js/timeStampUtility";
import Big from "big.js";
import chainConfig from "./chainConfig.js";

const toast = useToast();
const ENTRY_FEE = "6";
const ENTRY_FEE_WALLET = process.env.VUE_APP_ENTRY_FEE_WALLET;
const currentEnv = process.env.VUE_APP_ENV || "local";
const currentChainConfig = chainConfig[currentEnv];

const store = createStore({
  state() {
    return {
      chainConfig: currentChainConfig,
      userBalance: "0",
      hasEntryFee: false,
      isInitialized: false,
      isGameVisible: false,
      gamesLeft: null,
      highestScore: null,
      leaderboard: [],
      gameToken: null,
      gameState: null,
      prizePool: null,
      claimEndTime: null,
      remainingTime: null,
      allocation: null,
      web3: null,
      GameContract: null,
      W3MContract: null,
      web3Address: null,
      error: null,
      lastEntryFeeTx: null,
      gameScriptsLoaded: false,
      scoreSubmitted: false,
    };
  },
  mutations: {
    SET_GAME_SCRIPTS_LOADED(state, loaded) {
      state.gameScriptsLoaded = loaded;
    },
    SET_SCORE_SUBMITTED(state) {
      state.scoreSubmitted = true;
    },
    SET_LAST_ENTRY_FEE_TX(state, tx) {
      state.lastEntryFeeTx = tx;
    },
    SET_USER_BALANCE(state, balance) {
      state.userBalance = balance;
    },
    SET_ENTRY_FEE_STATUS(state, status) {
      state.hasEntryFee = status;
    },
    SET_INITIALIZED(state, initialized) {
      state.isInitialized = initialized;
    },
    SET_GAME_VISIBILITY(state, visibility) {
      state.isGameVisible = visibility;
    },
    UPDATE_GAMES_LEFT(state, data) {
      state.gamesLeft = data;
    },
    UPDATE_HIGHEST_SCORE(state, score) {
      state.highestScore = score;
    },
    UPDATE_LEADERBOARD(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    UPDATE_PRIZE_POOL(state, prizePool) {
      state.prizePool = prizePool;
    },
    SET_GAME_TOKEN(state, token) {
      state.gameToken = token;
      localStorage.setItem("gameToken", token);
    },
    SET_WEB3(state, web3Instance) {
      state.web3 = web3Instance;
    },
    SET_GAME_CONTRACT(state, contractInstance) {
      state.GameContract = contractInstance;
    },
    SET_W3M_CONTRACT(state, contractInstance) {
      state.W3MContract = contractInstance;
    },
    SET_GAME_STATE(state, gameState) {
      state.gameState = gameState;
    },
    SET_CLAIM_END_TIME(state, claimEndTime) {
      state.claimEndTime = claimEndTime;
    },
    SET_ALLOCATION(state, allocation) {
      state.allocation = allocation;
    },
    SET_REMAINING_TIME(state, remainingTime) {
      state.remainingTime = remainingTime;
    },
    SET_WEB3_ADDRESS(state, address) {
      state.web3Address = address;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_USER_DATA(state) {
      state.userBalance = "0";
      state.isInitialized = false;
      state.isGameVisible = false;
      state.gamesLeft = null;
      state.highestScore = null;
      state.leaderboard = [];
      state.gameToken = null;
      state.gameState = null;
      state.prizePool = null;
      state.claimEndTime = null;
      state.remainingTime = null;
      state.allocation = null;
      state.web3 = null;
      state.GameContract = null;
      state.W3MContract = null;
      state.web3Address = null;
      state.lastEntryFeeTx = null;
    },
  },
  getters: {
    isWalletConnected: () => account.connected,
    walletAddress: () => account.address,
    canPlayGame: (state) => {
      return parseFloat(state.userBalance) >= 200 && state.hasEntryFee;
    },
  },
  actions: {
    async authenticateUser() {
      try {
        if (!account.connected) {
          throw new Error("Web3 address not set");
        }
        let web3Address = account.address;
        console.log(process.env.VUE_APP_BACKEND_URL);
        const response = await axios.post(
          process.env.VUE_APP_BACKEND_URL + "/api/authenticate",
          { web3Address }
        );
        const { token } = response.data;
        localStorage.setItem("userToken", token);
        //toast.success("Authentication successful!");
        return token; // Return the token
      } catch (error) {
        console.error("Authentication failed:", error);
        toast.error("Authentication failed. Please try again.");
        throw error;
      }
    },
    async checkTokenValidity() {
      try {
        // Make a lightweight API call to check token validity
        await apiService.getLeftGames();
        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Invalid authentication.");
          return false;
        }
        toast.error("An error occurred while checking authentication.");
        throw error;
      }
    },
    async reauthenticate({ dispatch }) {
      try {
        localStorage.removeItem("userToken");
        await dispatch("authenticateUser");
        await dispatch("fetchInitialData");
        toast.success("Re-authenticated successfully!");
      } catch (error) {
        toast.error("Failed to re-authenticate. Please reconnect.");
        // You might want to redirect to a login page here
      }
    },
    async initializeContracts({ state, commit }) {
      if (!account.connected) return;
      const { gameContractAddress, tokenContractAddress, gameAbi, tokenAbi } =
        state.chainConfig.contracts;
      const web3 = new Web3(window.ethereum);

      const GameContract = new web3.eth.Contract(gameAbi, gameContractAddress);
      const W3MContract = new web3.eth.Contract(tokenAbi, tokenContractAddress);
      commit("SET_WEB3_ADDRESS", account.address);
      commit("SET_WEB3", web3);
      commit("SET_GAME_CONTRACT", GameContract);
      commit("SET_W3M_CONTRACT", W3MContract);
      //commit("SET_INITIALIZED", true);
    },
    async fetchInitialData({ dispatch, commit }) {
      try {
        await dispatch("authenticateUser");
        console.log("checking token");

        const fetchOperations = [
          dispatch("fetchUserBalance"),
          dispatch("fetchAllocation"),
          dispatch("fetchGameState"),
          dispatch("fetchPrizePool"),
          dispatch("fetchInitialHighScore"),
          dispatch("fetchInitialGamesLeft"),
          dispatch("fetchInitialLeaderboard"),
          dispatch("fetchClaimEndTime"),
          dispatch("fetchUser"),
        ];

        // Wait for all operations to complete
        await Promise.all(fetchOperations);

        // Now we can be sure all data is updated
        commit("SET_INITIALIZED", true);

        // This can now safely use the updated state
        await dispatch("checkEntryFeeStatus");

        //toast.success("Initial data loaded successfully!");
      } catch (error) {
        console.error("Error during initial data fetch:", error);
        toast.error(
          "Failed to load some initial data. Some features may be unavailable."
        );
        commit("SET_ERROR", "Authentication failed. Please try again.");
      }
    },
    async fetchGameState({ state, commit }) {
      if (!state.GameContract) return null;
      try {
        const gameState = await state.GameContract.methods
          .getCurrentState()
          .call();
        commit("SET_GAME_STATE", Number(gameState));
        return Number(gameState);
      } catch (error) {
        console.error("Failed to fetch game state:", error);
        toast.error("Failed to fetch game state.");
        return null;
      }
    },
    async fetchPrizePool({ state, commit }) {
      if (!state.W3MContract || !state.GameContract) return;
      try {
        const balance = await state.W3MContract.methods
          .balanceOf(state.GameContract.options.address)
          .call();
        const prizePool = state.web3.utils.fromWei(balance, "ether");
        commit("UPDATE_PRIZE_POOL", prizePool);
      } catch (error) {
        console.error("Failed to fetch prize pool:", error);
        toast.error("Failed to fetch prize pool.");
      }
    },
    async fetchClaimEndTime({ state, commit }) {
      if (!state.isInitialized || !state.GameContract) return;
      try {
        const claimEndTime = await state.GameContract.methods
          .claimEndTime()
          .call();
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = Number(claimEndTime) - currentTime;
        commit("SET_CLAIM_END_TIME", remainingTime);
      } catch (error) {
        console.error("Failed to fetch claim end time:", error);
        toast.error("Failed to fetch claim end time.");
      }
    },
    async fetchAllocation({ state, commit }) {
      if (!state.isInitialized || !state.GameContract) return;
      try {
        const allocation = await state.GameContract.methods
          .allocations(account.address)
          .call();
        const allocationInW3M = state.web3.utils.fromWei(allocation, "ether");
        commit("SET_ALLOCATION", allocationInW3M);
      } catch (error) {
        console.error("Failed to fetch allocation:", error);
        toast.error("Failed to fetch allocation.");
      }
    },
    async fetchInitialGamesLeft({ commit }) {
      try {
        const response = await apiService.getLeftGames();
        commit("UPDATE_GAMES_LEFT", response.data.leftGames);
      } catch (error) {
        console.error("Error fetching initial games left:", error);
      }
    },
    async fetchInitialHighScore({ commit }) {
      try {
        const response = await apiService.getHighestScore();
        commit("UPDATE_HIGHEST_SCORE", response.data.currentRoundScore);
      } catch (error) {
        console.error("Error fetching initial highest score:", error);
      }
    },
    async fetchInitialLeaderboard({ commit }) {
      try {
        const response = await apiService.getLeaderboard();
        commit("UPDATE_LEADERBOARD", response.data.allTimeLeaderboard);
      } catch (error) {
        console.error("Error fetching initial leaderboard:", error);
        toast.error("Error fetching initial leaderboard.");
      }
    },
    async fetchUser() {
      try {
        const response = await apiService.getUser();
        let userData = response.data.user;
        console.log(userData);
        if (userData.scoreSignature) {
          store.commit("SET_SCORE_SUBMITTED");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        toast.error("Error fetching user.");
      }
    },
    async fetchUserBalance({ state, commit }) {
      if (!state.W3MContract) return;
      try {
        const balance = await state.W3MContract.methods
          .balanceOf(account.address)
          .call();
        const balanceInEther = state.web3.utils.fromWei(balance, "ether");
        commit("SET_USER_BALANCE", balanceInEther);
      } catch (error) {
        console.error("Failed to fetch user balance:", error);
        toast.error("Failed to fetch user balance.");
        commit("SET_USER_BALANCE", "0");
      }
    },
    async fetchRemainingTime({ state, commit }) {
      try {
        if (!state.GameContract) {
          throw new Error("GameContract is not initialized");
        }
        const gameStartTime = await state.GameContract.methods
          .gameStartTime()
          .call();
        const gameDuration = await state.GameContract.methods
          .GAME_DURATION()
          .call();
        const endTime = Number(gameStartTime) + Number(gameDuration);

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const remainingTime = endTime - currentTime;
        //console.log("Remaining time (seconds):", remainingTime);

        commit("SET_REMAINING_TIME", remainingTime);
      } catch (error) {
        console.error("Failed to fetch remaining time:", error);
        toast.error("Failed to fetch remaining time.");
      }
    },
    async clearUserData({ state, commit }) {
      commit("CLEAR_USER_DATA", state);
      localStorage.removeItem("lastEntryFeeTimestamp");
    },
    async payEntryFee({ state, commit, dispatch }) {
      if (!state.isInitialized || !state.web3 || !state.W3MContract) {
        console.warn("Cannot check entry fee status: not fully initialized");
        toast.error("Game is not initialized. Please try again.");
        return;
      }

      try {
        // Check if user has enough balance to pay entry fee
        const balance = await state.W3MContract.methods
          .balanceOf(account.address)
          .call();
        const balanceInEther = state.web3.utils.fromWei(balance, "ether");

        if (parseFloat(balanceInEther) < parseFloat(ENTRY_FEE)) {
          toast.error(
            `Insufficient $Flappy balance to pay entry fee of ${ENTRY_FEE} tokens.`
          );
          return;
        }

        const entryFeeWei = state.web3.utils.toWei(ENTRY_FEE, "ether");

        // Send the entry fee directly to the designated wallet

        console.log(ENTRY_FEE_WALLET);
        console.log(process.env);
        const transaction = await state.W3MContract.methods
          .transfer(ENTRY_FEE_WALLET, entryFeeWei)
          .send({ from: account.address });

        if (transaction.status) {
          // Transaction was successful
          commit("SET_LAST_ENTRY_FEE_TX", transaction.transactionHash);

          // Store both timestamp and wallet address
          const currentTimestamp = Math.floor(Date.now() / 1000);
          localStorage.setItem(
            "lastEntryFeeData",
            JSON.stringify({
              timestamp: currentTimestamp,
              address: account.address,
            })
          );

          dispatch("checkEntryFeeStatus");
          dispatch("fetchUserBalance");

          toast.success("Entry fee paid successfully!");
        } else {
          // Transaction failed
          toast.error("Transaction failed. Please try again.");
        }
      } catch (error) {
        console.error("Failed to pay entry fee:", error);
        toast.error("Failed to pay entry fee. Please try again.");
      }
    },
    async checkEntryFeeStatus({ state, commit, dispatch }) {
      // First, check if we have stored data
      const storedData = localStorage.getItem("lastEntryFeeData");
      if (storedData) {
        const { timestamp, address } = JSON.parse(storedData);
        const isValid = isWithinPastHours(parseInt(timestamp), 1);
        if (isValid && address === account.address) {
          commit("SET_ENTRY_FEE_STATUS", true);
          console.log("entry fee status checked. setting game to visible");
          commit("SET_GAME_VISIBILITY", true);
          return;
        }
      }

      // If no valid stored data, proceed with blockchain check
      if (!state.web3 || !state.W3MContract) {
        console.error("Web3 or W3MContract not initialized");
        commit("SET_ENTRY_FEE_STATUS", false);
        return;
      }

      try {
        let txHash = state.lastEntryFeeTx;
        if (!txHash) {
          txHash = await dispatch("fetchRecentTransactions");
        }

        if (!txHash) {
          commit("SET_ENTRY_FEE_STATUS", false);
          return;
        }

        const receipt = await state.web3.eth.getTransactionReceipt(txHash);
        if (receipt && receipt.status) {
          const txBlock = await state.web3.eth.getBlock(receipt.blockNumber);
          const txTimestamp = parseInt(txBlock.timestamp);

          if (isWithinPastHours(txTimestamp, 24)) {
            commit("SET_ENTRY_FEE_STATUS", true);

            // Store both timestamp and wallet address
            localStorage.setItem(
              "lastEntryFeeData",
              JSON.stringify({
                timestamp: txTimestamp,
                address: account.address,
              })
            );
          } else {
            commit("SET_ENTRY_FEE_STATUS", false);
          }
        } else {
          commit("SET_ENTRY_FEE_STATUS", false);
        }
      } catch (error) {
        console.error("Failed to check entry fee status:", error);
        toast.error("Failed to check entry fee status.");
        commit("SET_ENTRY_FEE_STATUS", false);
      }
    },
    async fetchRecentTransactions({ state, commit }) {
      if (!state.isInitialized || !state.W3MContract || !state.web3) {
        console.error("Web3 or contracts not initialized");
        return null;
      }

      try {
        const latestBlock = await state.web3.eth.getBlockNumber();
        const fromBlock = Big(latestBlock).minus(5760).toString(); // Approximately 24 hours worth of blocks

        const events = await state.W3MContract.getPastEvents("Transfer", {
          filter: {
            from: state.web3Address,
            to: ENTRY_FEE_WALLET,
          },
          fromBlock: fromBlock,
          toBlock: "latest",
        });

        const entryFeeWei = state.web3.utils.toWei(ENTRY_FEE, "ether");
        const validTransactions = events.filter((event) => {
          return Big(event.returnValues.value).eq(Big(entryFeeWei));
        });

        if (validTransactions.length > 0) {
          const latestValidTx = validTransactions[validTransactions.length - 1];
          commit("SET_LAST_ENTRY_FEE_TX", latestValidTx.transactionHash);
          return latestValidTx.transactionHash;
        }

        return null;
      } catch (error) {
        console.error("Failed to fetch recent transactions:", error);
        toast.error("Failed to check recent entry fee payments.");
        return null;
      }
    },
    async setScoreSubmitted({ commit }) {
      commit("SET_SCORE_SUBMITTED");
    },
  },
});

export default store;
