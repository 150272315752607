<!-- Header.vue -->
<template>
  <div v-if="isInitialized">
    <div>
      <h1 class="text-2xl font-sans text-white p-5 text-center">Games left</h1>
    </div>
    <div class="border-2 mb-5 text-center">{{ gamesLeft }}/10</div>
  </div>
  <div v-else class="text-center">
    <span class="text-white">Loading...</span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GamesLeft",
  computed: {
    ...mapState({
      gamesLeft: (state) => state.gamesLeft,
      web3: (state) => state.web3,
      web3Address: (state) => state.web3Address,
      GameContract: (state) => state.GameContract,
    }),
    isInitialized() {
      return this.web3 && this.web3Address && this.GameContract;
    },
  },
  // Any header-specific logic or data properties can go here
};
</script>

<style>
/* Header-specific styles can go here, if needed */
</style>
